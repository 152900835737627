
export interface TableHeader {
  text: string | undefined;
  value: string;
  sortable?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
}

export interface TableRow {
  severity: string;
  timestamps: {
    startTimestamp: string;
    endTimestamp: string;
  }
  duration: string;
  closeVesselEventId?: number | null;
  conditionDetails: string;
  vesselName: string;
}
export const headers: TableHeader[] = [
  {
    text: "Severity",
    value: "severity",
    sortable: true,
    width: "110px",
  },
  {
    text: "Vessel",
    value: "vesselName",
    sortable: true,
    width: "200px",
  },
  {
    text: "Open / Closed",
    value: "timestamps",
    sortable: true,
    width: "280px",
  },
  {
    text: "Duration",
    value: "duration",
    sortable: true,
    width: "110px",
  },
  {
    text: "Condition & details",
    value: "conditionDetails",
    sortable: true,
    width: "430px",
  },
];
