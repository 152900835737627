













































import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
//  types
import { NotificationConditions } from "@/types/NotificationConditions";
import { UserProfile } from "@/types/userProfile";
import { Channel } from "@/types/condition";
//  components
import ConditionStepper from "@/components/Notifications/ConditionStepper.vue";

@Component({
  components: {
    ConditionStepper,
  },
})
export default class NotificationEditDialog extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @Prop({ required: true, default: () => {} }) notification!: NotificationConditions;
  @Prop({ required: true, default: () => [] }) commonSelectedUsers!: UserProfile[];
  @Prop({ required: true, default: () => [] }) commonSelectedEmails!: Channel[];
  @Prop({ default: 1 }) step!: number;

  // @Methods
  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.$emit("close");
  }

  save(): void {
    this.isModalActive = false;
    this.$emit("save");
  }

  update(notification: NotificationConditions): void {
    this.$emit("update", notification);
    this.isModalActive = false;
  }
}
