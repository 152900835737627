


















































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import dateHelper from "Utilities/date-helper";
//  types
import { Incident } from "@/types/Incident";
import { TableRow, headers } from "./VTableData";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {},
})
export default class IncidentsTable extends Vue {
  @Prop({ required: true, default: () => [] }) incidents!: Incident[];

  tableHeaders = headers;
  incidentTypes: string[] = ["Open", "Closed"];
  selectedIncidentType: string | null = "Open";
  searchQuery: string | null = null;

  // @Getters
  get tableRowItems(): TableRow[] {
    if (!this.incidents?.length) return [];

    let tableRows: TableRow[] = [];
    const filtredIncidents = this.selectedIncidentType === "Open" ? this.incidents.filter(row => row.closeVesselEventId === null) : this.incidents.filter(row => Boolean(row.closeVesselEventId));
    filtredIncidents.forEach((item: Incident) => {
      const row: TableRow = {
        severity: item.severity,
        vesselName: this.vesselName(item.vesselId),
        timestamps: {
          startTimestamp: item.openVesselEvent ? dateHelper.getFormatedDateTimeString(item.openVesselEvent.timestamp) : "",
          endTimestamp: item.closeVesselEvent ? dateHelper.getFormatedDateTimeString(item.closeVesselEvent.timestamp) : "",
        },
        duration: this.durationStr(item),
        closeVesselEventId: item.closeVesselEventId,
        conditionDetails: item.conditionDetails,
      };
      tableRows.push(row);
    });
    if (this.searchQuery) {
      tableRows = tableRows.filter(
        row =>
          row.severity.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
          row.duration.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
          row.timestamps.startTimestamp.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
          row.timestamps.endTimestamp.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
          row.conditionDetails.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
          row.vesselName.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "")
      );
    }
    return tableRows;
  }

  get isNoData(): boolean {
    return false;
  }

  // @Methods
  durationStr(incident: Incident): string {
    return dateHelper.getHumanizeDuration(incident.openVesselEvent.timestamp, incident.closeVesselEvent?.timestamp);
  }

  severityColor(severity: string): string {
    return severity === "Critical" ? "error" : "warning";
  }

  vesselName(vesselId: number): string {
    const vessel = Vessels.getExtendedVesselById(vesselId);
    return vessel ? vessel.name : "";
  }

  customSort(items: [], sortBy: any, isDesc: any): [] {
    //do nothing when the sorting is reset
    if (!sortBy[0]) return items;

    //sort all other columns as strings
    return items.sort((a: any, b: any) => {
      if (isDesc[0]) return b[sortBy] < a[sortBy] ? -1 : 1;

      return a[sortBy] < b[sortBy] ? -1 : 1;
    });
  }
}
