















































































































































































import { Component, Vue, Ref } from "vue-property-decorator";
import store from "@/store";
import _ from "underscore";
// types
import { Incident } from "@/types/Incident";
import { Condition, FleetThresholds, Channel } from "@/types/condition";
import { ExtendedVessel } from "@/types/Vessel";
import { UserProfile } from "@/types/userProfile";
import { NotificationConditions } from "@/types/NotificationConditions";

// components
import IncidentsTable from "@/components/Notifications/IncidentsTable.vue";
import NotificationConfiguration from "@/components/Notifications/NotificationConfiguration.vue";
import NotificationEditDialog from "@/components/Notifications/NotificationEditDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import NotificationSkeletonLoader from "@/components/Notifications/NotificationSkeletonLoader.vue";

// modules
import { getModule } from "vuex-module-decorators";
import IncidentsModule from "@/store/clients/Incidents.module";
import VesselsModule from "@/store/clients/Vessels.module";
import UsersModule from "@/store/clients/Users.module";
import ChannelsModule from "@/store/clients/Channels.module";
import SnackbarModule from "@/store/clients/Snackbar.module";

const Incidents = getModule(IncidentsModule, store);
const Vessels = getModule(VesselsModule, store);
const Users = getModule(UsersModule, store);
const Channels = getModule(ChannelsModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  components: {
    IncidentsTable,
    NotificationConfiguration,
    NotificationSkeletonLoader,
    NotificationEditDialog,
    ConfirmDialog,
  },
})
export default class MyNotifications extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;
  // @Data
  tab = null;
  NotificationEditDialog = false;
  selectedConditions: Condition[] = [];
  conditionStep = 1;
  searchQuery: string | null = null;
  noResults = false;
  groupedConditions: Condition[][] = [];
  ungroupedConditions: Condition[] = [];
  debounceLoader = false;

  updateSearchQueryInput(newValue: string): void {
    this.searchQuery = newValue;
    this.groupedConditions = this.getGroupedConditions;
    this.ungroupedConditions = this.getUngroupedConditions;
    setTimeout(() => (this.debounceLoader = false), 500);
  }

  doSearchDebounced = _.debounce(this.updateSearchQueryInput, 1000);

  // @Getters
  get allIncidents(): Incident[] {
    return Incidents.allIncidents;
  }

  get conditions(): Condition[] {
    return Incidents.conditions;
  }

  get notification(): NotificationConditions {
    return {
      vessels: this.getExtendedVesselsFromCondition(this.selectedConditions),
      conditions: this.selectedConditions,
    };
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get getGroupedConditions(): Condition[][] {
    this.debounceLoader = true;
    const groupedConditions: any = _.groupBy(
      this.conditions.filter(cond => cond.isDeleted === false),
      "groupGuid"
    );
    delete groupedConditions["null"];
    let convertedToArray = Object.values(groupedConditions).reverse() as Condition[][];
    if (!Boolean(this.searchQuery)) return convertedToArray;

    //  if searchable item is a vessel < >
    let vesselInSearch: ExtendedVessel | null = null;
    const isVesselInSearch = this.extendedVessels.some(v => {
      if (v.name.toLowerCase().includes((this.searchQuery as string).toLowerCase())) {
        vesselInSearch = v;
        return true;
      }
    });
    if (isVesselInSearch) {
      convertedToArray = convertedToArray.filter(item => {
        const hasSearchQuery = item.filter(condition => {
          if (condition.vesselId === vesselInSearch?.id) {
            return condition;
          }
        });
        return hasSearchQuery.length ? item : false;
      });
      return convertedToArray;
    }
    //  if searchable item is a vessel </>

    convertedToArray = convertedToArray.filter(item => {
      if (item.some(condition => condition.channels.some(ch => ch.email?.toLowerCase().includes(this.searchQuery?.toLowerCase() ?? "")))) {
        return item;
      }
      const hasSearchQuery = item.filter(condition => {
        if (
          condition.conditionType.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
          condition.conditionDetails?.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
          condition.thresholds.filter(thr => thr.severity.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "")).length
        ) {
          return condition;
        }
      });
      return hasSearchQuery.length ? item : false;
    });
    return convertedToArray;
  }

  get getUngroupedConditions(): Condition[] {
    this.debounceLoader = true;
    const groupedConditions: any = _.groupBy(
      this.conditions.filter(cond => cond.isDeleted === false),
      "groupGuid"
    );
    if (!groupedConditions.null) return [] as Condition[];
    if (!this.searchQuery) return groupedConditions.null;

    //  if searchable item is a vessel < >
    let vesselInSearch: ExtendedVessel | null = null;
    const isVesselInSearch = this.extendedVessels.some(v => {
      if (v.name.toLowerCase().includes((this.searchQuery as string).toLowerCase())) {
        vesselInSearch = v;
        return true;
      }
    });
    if (isVesselInSearch) return groupedConditions.null.filter((condition: Condition) => condition.vesselId === vesselInSearch?.id);
    //  if searchable item is a vessel </>

    return groupedConditions.null.filter((condition: Condition) => {
      if (
        condition.conditionType.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
        condition.conditionDetails?.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "") ||
        condition.thresholds.filter(thr => thr.severity.toLocaleLowerCase().includes(this.searchQuery?.toLocaleLowerCase() ?? "")).length ||
        condition.channels.filter(ch => ch.email && ch.email.toLowerCase().includes(this.searchQuery?.toLowerCase() ?? "")).length
      ) {
        return condition;
      }
    });
  }

  get noData(): boolean {
    return this.groupedConditions.length === 0 && this.ungroupedConditions.length === 0 && this.debounceLoader === false;
  }

  isEventShapoliChecked(conditions: Condition[]): boolean {
    return Boolean(conditions.filter(cond => cond.conditionType === "ShapoliPowerReserveBreach").length);
  }

  isEventVoyageCompleteChecked(conditions: Condition[]): boolean {
    return Boolean(conditions.filter(cond => cond.conditionType === "VoyageComplete").length);
  }

  isEventVariableOfflineChecked(conditions: Condition[]): boolean {
    return Boolean(conditions.filter(cond => cond.conditionType === "VariableOffline").length);
  }

  isEventMissingSignalChecked(conditions: Condition[]): boolean {
    return Boolean(conditions.filter(cond => cond.conditionType === "MissingSignal").length);
  }

  // @Methods
  getExtendedVesselsFromCondition(conditions: Condition[]): ExtendedVessel[] {
    const uniqueByVesselId = _.unique(conditions, x => x.vesselId);
    return uniqueByVesselId.map(cond => Vessels.getExtendedVesselById(cond.vesselId)) as ExtendedVessel[];
  }

  getThresholds(conditions: Condition[]): FleetThresholds {
    const logDataMathThresholds = conditions.find(cond => cond.conditionType === "LogDataMathExpression")?.thresholds;
    const logDataThresholds = conditions.find(cond => cond.conditionType === "LogDataExpression")?.thresholds;
    return {
      VesselDataOverdueThresholds: conditions.find(cond => cond.conditionType === "VesselDataOverdue")?.thresholds ?? [],
      LogDataExpressionThresholds: logDataThresholds ?? logDataMathThresholds ?? [],
      LogDataMathExpressionThresholds: logDataMathThresholds ?? logDataThresholds ?? [],
      ManualInputValueStaleThresholds: conditions.find(cond => cond.conditionType === "ManualInputValueStale")?.thresholds ?? [],
      PerformanceStatusNotOkThresholds: conditions.find(cond => cond.conditionType === "PerformanceStatusNotOk")?.thresholds ?? [],
      PerformanceStatusObserveThresholds: conditions.find(cond => cond.conditionType === "PerformanceStatusObserve")?.thresholds ?? [],
      SpeedLossWarningThresholds: conditions.find(cond => cond.conditionType === "SpeedLossWarning")?.thresholds ?? [],
      SpeedLossCriticalThresholds: conditions.find(cond => cond.conditionType === "SpeedLossCritical")?.thresholds ?? [],
      FuelConsumptionWarningThresholds: conditions.find(cond => cond.conditionType === "FuelConsumptionWarning")?.thresholds ?? [],
      FuelConsumptionCriticalThresholds: conditions.find(cond => cond.conditionType === "FuelConsumptionCritical")?.thresholds ?? [],
      ShapoliPowerReserveBreachThresholds: conditions.find(cond => cond.conditionType === "ShapoliPowerReserveBreach")?.thresholds ?? [],
      VoyageCompleteThresholds: conditions.find(cond => cond.conditionType === "VoyageComplete")?.thresholds ?? [],
      VariableOfflineThresholds: conditions.find(cond => cond.conditionType === "VariableOffline")?.thresholds ?? [],
      MissingSignalThresholds: conditions.find(cond => cond.conditionType === "MissingSignal")?.thresholds ?? [],
    };
  }
  onNotificationEditDialogClosed(): void {
    this.selectedConditions = [];
    this.conditionStep = 1;
    this.NotificationEditDialog = false;
  }

  getUsers(conditions: Condition[]): UserProfile[] {
    // *underscore methods _.intersection works only with primitives and channels should be converted to an array of strings
    const channels: any = [];
    //  collect all channel arrays to an arry
    conditions.forEach(cond => channels.push(cond.channels));
    //  replace channel object with the userId string (array of strings)
    channels.forEach((item: any, i: number) => {
      channels[i] = item.map((x: any) => {
        if (x.userId) return x.userId;
      });
    });
    //  get only intersected userIds and return as channel object
    const strCommonSelectedUsers = _.intersection(...channels);
    const commonSelectedUsers: UserProfile[] = [];
    strCommonSelectedUsers.forEach((userId: any) => {
      const user = Users.getUserById(userId) as UserProfile;
      if (user) commonSelectedUsers.push(user);
    });
    return commonSelectedUsers;
  }

  getEmails(conditions: Condition[]): Channel[] {
    // *underscore methods _.intersection works only with primitives and channels should be converted to an array of strings
    const channels: any = [];
    //  collect all channel arrays to an arry
    conditions.forEach(cond => channels.push(cond.channels));
    //  replace channel object with the email string (array of strings)
    channels.forEach((item: any, i: number) => (channels[i] = item.map((x: any) => x.email)));
    //  get only intersected channels and return channel as an object
    const strCommonSelectedEmails = _.intersection(...channels);
    const commonSelectedEmails: Channel[] = [];
    strCommonSelectedEmails.forEach((email: any) => {
      const channel = Channels.getChannelByEmail(email) as Channel;
      if (channel && channel.userId == null) commonSelectedEmails.push(channel);
    });
    return commonSelectedEmails;
  }

  async onNotificationUpdate(notification: NotificationConditions): Promise<void> {
    try {
      await Incidents.updateCondition(notification.conditions);
      await Incidents.fetchConditions();
      this.groupedConditions = this.getGroupedConditions;
      this.ungroupedConditions = this.getUngroupedConditions;
      this.debounceLoader = false;
      Snackbar.showSnackbar({
        text: "Notification was successfully <b>updated</b>",
        color: "success",
      });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update notification" });
    }
  }

  onEditNotification(conditions: Condition[]): void {
    this.selectedConditions = conditions;
    console.log("this.selectedConditions:", this.selectedConditions);
    this.NotificationEditDialog = true;
  }

  onOpenChannels(conditions: Condition[]): void {
    this.conditionStep = 3;
    this.selectedConditions = conditions;
    this.NotificationEditDialog = true;
  }

  async deleteNotification(conditions: Condition[]): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete notification?", "Are you sure you want to delete this notification?");
    if (!confirmed) return;
    conditions.forEach(cond => (cond.isDeleted = confirmed));
    try {
      await Incidents.updateCondition(conditions);
      await Incidents.fetchConditions();
      this.groupedConditions = this.getGroupedConditions;
      this.ungroupedConditions = this.getUngroupedConditions;
      this.debounceLoader = false;
      Snackbar.showSnackbar({
        text: "Notification was successfully <b>deleted</b>",
        color: "success",
      });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to delete notification" });
    }
  }

  // @Hooks
  async mounted(): Promise<void> {
    this.debounceLoader = true;
    await Users.refreshUsers();
    await Channels.fetchChannels();
    await Incidents.fetchConditions();
    await Incidents.getAllOpenIncidents();
    await Incidents.getAllClosedIncidents();
    this.groupedConditions = this.getGroupedConditions;
    this.ungroupedConditions = this.getUngroupedConditions;
    this.debounceLoader = false;
  }
}
